import { render, staticRenderFns } from "./carousel-item.vue?vue&type=template&id=3077c644&scoped=true&"
import script from "./carousel-item.vue?vue&type=script&lang=js&"
export * from "./carousel-item.vue?vue&type=script&lang=js&"
import style0 from "./carousel-item.vue?vue&type=style&index=0&id=3077c644&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3077c644",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonImage: require('/builds/wj/fe/wenjuan-homesite/components/common/image.vue').default,CommonButton: require('/builds/wj/fe/wenjuan-homesite/components/common/button.vue').default,CommonTextRaw: require('/builds/wj/fe/wenjuan-homesite/components/common/text-raw.vue').default,CommonSvg: require('/builds/wj/fe/wenjuan-homesite/components/common/svg.vue').default})
