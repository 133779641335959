import { render, staticRenderFns } from "./carouselList.vue?vue&type=template&id=0eb61c7c&scoped=true&"
import script from "./carouselList.vue?vue&type=script&lang=js&"
export * from "./carouselList.vue?vue&type=script&lang=js&"
import style0 from "./carouselList.vue?vue&type=style&index=0&id=0eb61c7c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eb61c7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonImage: require('/builds/wj/fe/wenjuan-homesite/components/common/image.vue').default,CommonButton: require('/builds/wj/fe/wenjuan-homesite/components/common/button.vue').default,CommonGridRow: require('/builds/wj/fe/wenjuan-homesite/components/common/grid-row.vue').default})
