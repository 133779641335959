import { render, staticRenderFns } from "./carousel-step.vue?vue&type=template&id=06837dca&scoped=true&"
import script from "./carousel-step.vue?vue&type=script&lang=js&"
export * from "./carousel-step.vue?vue&type=script&lang=js&"
import style0 from "./carousel-step.vue?vue&type=style&index=0&id=06837dca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06837dca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonCarouselItem: require('/builds/wj/fe/wenjuan-homesite/components/common/carousel-item.vue').default,CommonCarouselList: require('/builds/wj/fe/wenjuan-homesite/components/common/carouselList.vue').default})
