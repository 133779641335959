
import { mapState } from 'vuex'
import { debounce } from '~/utils/tools'
import { useMemo } from '~/utils/tools.js'

export default {
  name: 'CarouselList',
  props: {
    className: {
      type: String,
      default: '',
    },
    // 固定显示大小
    fixedSize: {
      type: Number,
      default: 0,
    },
    indicatorPosition: {
      type: String,
      default: 'out',
    },
    dataList: {
      type: Array,
      default: Array,
    },
    // 宽度改变的回调
    widthChange: {
      type: Function,
      default: null,
    },
    //  是不是要填充最后一组数据保持每衣板块都是充满 例如 [1,2,3,4,5,6,7,8] => [[1,2,3],[4,5,6],[6,7,8]]
    fillLastArray: {
      type: Boolean,
      default: true,
    },
    // 读取图片的对象的属性
    srcKey: {
      type: String,
      default: 'src',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    // 是否自动轮播
    autoplay: {
      type: Boolean,
      default: false,
    },
    // 自动轮播时间间隔
    interval: {
      type: Number,
      default: 5000,
    },
    href: {
      type: Function,
      default: null,
    },
    useSplitEnd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      memo: useMemo(),
      preNode: true,
      nextNode: false,
    }
  },
  computed: {
    ...mapState(['innerWidth']),
    list() {
      const { dataList = [], showSize: size, fillLastArray } = this
      let ait = [] // 永远是最后一组
      let pre = [] // 永远是前一组
      const qrList = dataList.reduce((p, n, i) => {
        if (i % size === 0) {
          pre = ait
          ait = [n]
          p.push(ait)
        } else {
          ait.push(n)
        }
        return p
      }, [])
      if (ait.length < size && pre.length === size && fillLastArray) {
        const _list = pre.slice(ait.length - size)
        ait.unshift(..._list)
      }
      return qrList
    },
    showSize() {
      const { widthChange, innerWidth } = this
      if (this.fixedSize) return this.fixedSize
      if (widthChange) return widthChange?.(innerWidth)
      // 默认以4 5 切换
      if (innerWidth <= 1366) {
        return 4
      }
      return 5
    },
  },
  watch: {
    list: {
      handler(cval) {
        if (!this.useSplitEnd) return
        this.memo(cval.length, this.setM)
      },
      immediate: true,
    },
  },

  methods: {
    reset() {
      this.$refs.swipe?.resize()
    },
    handler(item, ii, field, i) {
      this.$emit('clickItem', item, ii, field, i)
    },
    change(...list) {
      const {
        useSplitEnd,
        $listeners: { change },
      } = this
      if (change) return this.$emit('change', ...list)
      if (!useSplitEnd) return
      const [pre, next] = list
      this.preNode = pre === 0
      this.nextNode = next === 0
    },
    setM: debounce(function () {
      const {
        $refs: { carousel },
      } = this
      if (carousel) {
        const { activeIndex, items } = carousel
        this.preNode = items.length === activeIndex + 1 || (items.length > 1 && activeIndex === 0)
        this.nextNode = items.length === activeIndex + 1
      }
    }, 200),
  },
}
