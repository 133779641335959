
import { mapState } from 'vuex'
import { useMemo } from '~/utils/tools'
export default {
  name: 'GridRow',
  props: {
    // 使用flex布局 flex+cols 会自动生成布局，且行的最后一列的宽度为0
    flex: {
      type: Boolean,
      default: false,
    },
    // 仅仅  默认使用5-6在1366之前切换， 其他场景不使用 请使用flex+cols 布局 或者Grid 布局
    useDefaultLayout: {
      type: Boolean,
      default: false,
    },
    // flex 配合使用 是否应许换行
    flexWrap: {
      type: Boolean,
      default: true,
    },
    // 滚动的内容区域的 对齐模式
    align: {
      type: String,
      default: 'left',
    },
    // 列行数，flex布局下 显示列数且对应最后一列是
    cols: {
      type: [String, Number],
      default: 6,
    },
    // 行高[ 170]
    rows: {
      type: Array,
      default: null,
    },
    // 间距空隙
    gapCol: {
      type: [Number, String],
      default: '22px',
    },
    // 设置行间距空隙
    gapRow: {
      type: [Number, String],
      default: '20px',
    },
    // 当变化的时候 优先使用  width => width
    change: {
      type: Function,
      default: null,
    },
    // 设置变化栅栏布局的时间间隔
    changeTime: {
      type: Number,
      default: 80,
    },
    // 采用优化的change
    memo: {
      type: Boolean,
      default: false,
    },
    // 自动填充的宽度
    fillWidth: {
      type: String,
      default: '',
    },
    // 自定义 列的配置,优先级最高 配置
    gridColumns: {
      type: String,
      default: '',
    },
  },
  emits: {
    change: Function, // 宽度变化
  },
  data() {
    return {
      state: this.$store.state,
      useMemo: useMemo(),
    }
  },
  computed: {
    ...mapState(['realWidth']),
    objectStyle() {
      const { cols, gapCol, gapRow, rows, gridColumns } = this
      const _cols = cols
      const colWidth = String(gapCol).endsWith('px') ? gapCol : gapCol + 'px'
      const rowWidth = String(gapRow).endsWith('px') ? gapRow : gapRow + 'px'
      // flex 布局
      if (this.flex || rows === 1)
        return {
          '--marginRight': colWidth || '29px',
          '--marginBootom': rowWidth,
          '--cols': _cols,
          ...(rows === 1
            ? {}
            : {
                '--boundary': _cols,
              }),
        }

      // grid 布局
      const fillWidth =
        gridColumns || (this.fillWidth ? `repeat(auto-fill, ${this.fillWidth})` : `repeat(${_cols},1fr)`)
      const surplurs = {
        gridTemplateColumns: fillWidth,
        gridColumnGap: colWidth,
        gridRowGap: rowWidth,
        gridTemplateRows: rows ? rows?.map?.((v) => (v?.endsWith?.('px') ? v : v + 'px'))?.join?.(' ') : null,
      }
      return surplurs
    },
  },
  watch: {
    'state.innerWidth': {
      handler(cval) {
        const maxWidth =
          cval < 980
            ? 980
            : cval < 1250
            ? 1250
            : cval < 1330
            ? 1330
            : cval < 1366
            ? 1366
            : cval < 1440
            ? 1440
            : cval < 1920
            ? 1920
            : 2560 // 判断宽度响应
        const DOM = {
          width: cval, // 实时宽度
          mobileWidth: cval, // 没有滚动条的宽度端宽度
          maxWidth, // 临界点
        }
        // 实时返回宽度
        if (!this.memo) return this.change?.(DOM) || this.$emit('change', DOM) // 高阶函数 请优先使用 props 的方式监听
        // 临界点才返回
        this.useMemo(maxWidth, () => {
          this.change?.(DOM) || this.$emit('change', DOM)
        })
      },
      immediate: true,
    },
  },
}
