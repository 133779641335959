
import createQrcode from 'qrcode'
export default {
  name: 'CarouselItem',
  props: {
    // 数据对象
    data: {
      type: Object,
      default: Object,
    },
    // 属性匹配
    props: {
      type: Object,
      default: Object,
    },
    // 使用卡片自定义样式
    useCard: {
      type: Boolean,
      default: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enterCode: false, // 进入二维码显示
      showSplit: false, // 显示省略号
      qrcodeImg: '',
      dataSRC: '',
    }
  },
  computed: {
    keys() {
      return {
        src: 'src',
        oid: 'oid',
        title: 'title',
        desc: 'desc',
        num: 'num',
        btnText: 'btnText',
        href: 'href',
        ref_count: 'ref_count',
        ...this.props,
      }
    },
  },
  mounted() {
    this.$nextTick(async (_) => {
      // const height = this.$refs['text-desc'].offsetHeight
      // this.showSplit = height > 96
      this.dataSRC = `${window.location.origin}/m/lib_detail/${this.data[this.keys.oid]}`
      this.qrcodeImg = await createQrcode.toDataURL(this.dataSRC, {})
    })
  },
}
