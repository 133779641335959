
import { createObserver } from '~/utils/tools.js'
export default {
  author: 'kyol',
  name: 'TextRaw',
  props: {
    text: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: String,
      default: ''
    },
    // 省略号表示方式，自定义模式显示监听模式，传统模式只有谷歌浏览器支持
    type: {
      type: Boolean,
      default: true
    },
    // 最大显示行数
    row: {
      type: [Number, String],
      default: 4
    }
  },
  data() {
    return {
      showSplit: false
    }
  },
  mounted() {
    !this.type &&
      createObserver()
        .add(this.$refs['text-raw'])
        .then((intersecting) => {
          this.showSplit = !intersecting
        })
  }
}
