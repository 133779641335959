
import { createObserver, throttle } from '~/utils/tools.js'
export default {
  name: 'CarouselStep',
  props: {
    // 移动前操作 可以拦截数据
    beforeMove: {
      type: Function,
      default: null,
    },
    // 移动步伐
    moveStep: {
      type: [Number, String],
      default: '306px',
    },
    // 卡片的宽
    cardWidth: {
      type: String,
      default: '',
    },
    // 卡片的高度
    cardHeight: {
      type: String,
      default: '',
    },
    // 设置卡片的样式， 这里可以设置自定义卡片的宽高 背景色
    cardClass: {
      type: String,
      default: '',
    },
    //  卡片显示方式 main, 滚动方式，main  固定大小步伐滚动，wrap整体滑上使用flex布局的时候用移动端
    scroll: {
      type: String,
      default: 'main',
    },
    // 辊轴层离的外边距
    margin: {
      type: [Number, String],
      default: '0',
    },
    // 卡片数据
    dataList: {
      type: Array,
      default: null,
    },
    // 移动端 列数
    mobileCols: {
      type: Number,
      default: 2,
    },
    // 动画速度
    moveVelocity: {
      type: String,
      default: '0.5s',
    },
    // 是否采用自动识别边界阻止滚动
    auto: {
      type: Boolean,
      default: false,
    },
    // 使用默认按钮, 假 =》关闭左右切换按钮，真 =》开启 切换按钮
    useDirection: {
      type: Boolean,
      default: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    // 第一个卡片的外左边距， 也可以使用margin属性设置整体滚轴的边距
    marginLeft: {
      type: String,
      default: '42px',
    },
    // 使用卡片的默认阴影
    cardShadow: {
      type: Boolean,
      default: false,
    },
    // arrow class
    arrowIconClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tagList: {
        left: '',
        right: '',
      },
      // 边界点
      tagTouch: {
        left: false,
        right: false,
      },
      totalSize: 0, // 移动距离
      disabled: {
        // 当auto的时候 禁用按钮
        left: false, // 是否禁用
        right: false,
      },
      wrap: null,
      observer: null,
    }
  },
  computed: {
    disabledBtnDirection() {
      return this.disabled.left && this.disabled.right
    },
    slotItem() {
      return this.$slots?.item || null
    },
    // 纯手机设备
    isHandset() {
      return this.$store.state.isHandset
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getTags()
      if (this.auto) {
        this.disabled = this.tagTouch
      }
      if (!this.isMobile)
        this.observer = createObserver()
          .add(this.tagList.left)
          .then((intersecting) => {
            this.tagTouch.left = intersecting
            intersecting && this.$emit('boundary', { type: 'left', value: intersecting })
          })
          .add(this.tagList.right)
          .then((intersecting) => {
            this.tagTouch.right = intersecting
            intersecting && this.$emit('boundary', { type: 'right', value: intersecting })
          })
    },
    getTags() {
      this.tagList = {
        left: this.$refs['direction-left'],
        right: this.$refs['direction-right'],
      }
      this.wrap = this.$refs['step-wrap']
    },
    // 属性计算
    transtion(split = '') {
      const realmoveSize = Number((split + this.moveStep).replace(/px/gi, ''))
      this.totalSize += realmoveSize
      Object.assign(this.wrap.style, {
        transform: `translateX(${this.totalSize}px)`,
      })
    },
    // 移动函数 thunk 函数是高阶拦截函数
    moving(directionType, thunk, isDelay = 500) {
      let isFlag = true
      if (this.auto) {
        isFlag = !this.tagTouch[directionType]
      }
      if (thunk || this.beforeMove) {
        isFlag = (thunk || this.beforeMove)(this.tagTouch)
      }
      isFlag && throttle(this.transtion, isDelay, directionType === 'left' ? '' : '-')
    },
    // 重置
    reset() {
      this.totalSize = 0
      if (this.isMobile) {
        const wrap = this.$refs.carouselList
        wrap.reset()
      } else {
        Object.assign(this.wrap.style, {
          transform: `translateX(${this.totalSize}px)`,
        })
      }
    },
    clickItem(item, ii, field, i) {
      this.$emit('clickItem', item, ii, field, i)
    },
    // 移动端触发事件
    touchstart(evt) {
      this.touchstart.startX = evt.touches[0].pageX
      this.touchstart.timeStamp = new Date().getTime()
      this.touchstart.startY = evt.touches[0].pageY
    },
    touchend(evt) {
      if (this.isMobile) return false
      const num = this.touchstart.moveX - this.touchstart.startX
      const numY = Math.abs(this.touchstart.moveY - this.touchstart.startY)
      const resplse = Number(numY / Math.abs(num)).toFixed(4)
      const clie = resplse < 0.3
      if (!clie) return
      evt.preventDefault()
      evt.reslut = false
      // 点击左边，或者向右偏移
      if (num >= 0 && !this.tagTouch.left) {
        this.moving('left', '', 500)
      }
      if (num < 0 && !this.tagTouch.right) {
        this.moving('right', '', 500)
      }
    },
    touchmove(evt) {
      this.touchstart.moveX = evt.touches[0].pageX
      this.touchstart.moveY = evt.touches[0].pageY
    },
  },
}
